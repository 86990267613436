
export default class TMslideshow {

    constructor(el){
        this.DOM = {el: el};
        this.DOM.slides = [...this.DOM.el.querySelectorAll('.slide')];
        this.currentSlideIndex = 0;
        this.currentSlideCount = 1;
        this.totalSlideCount = this.DOM.slides.length;
        this.slideCountContainer = this.DOM.el.querySelector('#current-slide-count');
        this.slideTotalContainer = this.DOM.el.querySelector('#total-slide-count');
        
        //Init Viewport
        this.DOM.slideshowViewport = document.createElement("div");
        this.DOM.slideshowViewport.classList.add('slideshow-viewport');  
        this.DOM.el.appendChild(this.DOM.slideshowViewport); 

        this.selectSlide(this.currentSlideIndex);
        this.initContainer();
        this.initControls();

        // this.test( this.DOM.el);
        var slideshow = this;

        // EVENT LISTENERS
        this.DOM.el.addEventListener('click', function (event) {
            // PROJECT TEXT TOGGLE BUTTONS
            if (event.target.classList.contains('next-slide-trigger')){
                slideshow.nextSlide();
            }
            if (event.target.classList.contains('prev-slide-trigger')){
                slideshow.prevSlide();
            }
        
        }, false);
    }

    initControls(){
        var next = document.createElement("button");
        next.classList.add('button', 'next-slide-button', 'next-slide-trigger');   
        this.DOM.el.appendChild(next); 
        var prev = document.createElement("button");
        prev.classList.add('button', 'prev-slide-button', 'prev-slide-trigger');   
        this.DOM.el.appendChild(prev); 
    }
    
    test(el) {
        console.log(el);
    }

    updateCount(i, t){
        this.slideCountContainer.innerHTML = i;
        this.slideTotalContainer.innerHTML = t;
    }

    initContainer(){
        this.DOM.slides.forEach((element) => {
           this.DOM.slideshowViewport.appendChild(element);
        });
        
    }

    selectSlide(i){
        // console.log(i);
        this.DOM.activeSlide = this.DOM.slides[i];
        this.DOM.activeSlide.classList.add('active-slide');
        this.updateCount(this.currentSlideIndex+1, this.totalSlideCount);
    }

    nextSlide() {
        this.DOM.activeSlide.classList.remove('active-slide');
        if(this.currentSlideIndex == (this.totalSlideCount - 1)){
            this.currentSlideIndex = 0;
        }else{
            this.currentSlideIndex ++;
        }
        this.selectSlide(this.currentSlideIndex);
    }

    prevSlide() {
        console.log(this.currentSlideIndex);
        this.DOM.activeSlide.classList.remove('active-slide');
        if(this.currentSlideIndex == 0){
            this.currentSlideIndex = (this.totalSlideCount-1);
        }else{
            this.currentSlideIndex --;
        }
        this.selectSlide(this.currentSlideIndex);
    }

}