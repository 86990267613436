/*
================================================================
IMPORTS
================================================================
*/

var _ = require('underscore');

import 'lazysizes';

import objectFitImages from 'object-fit-images';

var tm_body = document.querySelector('body');

import gsap from "gsap";
// import CustomEase from 'gsap/CustomEase';
import barba from '@barba/core';

import CC from "CookieConsent";


/*
================================================================
ACCESSIBILITY
================================================================
*/

(function () {
    'use strict';

    function keyboardFocus (e) {
        if (e.keyCode !== 9) {
            return;
        }

        switch (e.target.nodeName.toLowerCase()) {
            case 'input':
            case 'select':
            case 'textarea':
                break;
            default:
                document.documentElement.classList.add('keyboard-focus');
                document.removeEventListener('keydown', keyboardFocus, false);
        }
    }

    document.addEventListener('keydown', keyboardFocus, false);
})();


/*
================================================================
POLYFILLS
================================================================
*/

objectFitImages();


/*
================================================================
NAVIGATION
================================================================
*/

var toggle = document.querySelector('#menu-toggle-button');
var menu = document.querySelector('.main-menu-container');
var siteTitle = document.querySelector('.site-title');

toggle.addEventListener('click', toggleMenu);

function toggleMenu(){
    if (menu.classList.contains('is-active')) {
        tm_body.classList.remove('menu--open');
        toggle.setAttribute('aria-expanded', 'false');
        toggle.classList.remove('is-active');
        menu.classList.remove('is-active');
    } else {
        tm_body.classList.add('menu--open');
        menu.classList.add('is-active'); 
        toggle.setAttribute('aria-expanded', 'true');
        toggle.classList.add('is-active');
    }
}

function closeMenu(){
    tm_body.classList.remove('menu--open');
    toggle.setAttribute('aria-expanded', 'false');
    toggle.classList.remove('is-active');
    menu.classList.remove('is-active');
}


/*
================================================================
VH FIX
================================================================
*/

const appHeight = () => {
    const doc = document.documentElement;
    doc.style.setProperty('--app-height', `${window.innerHeight}px`);
    console.log('opop');
}
window.addEventListener('resize', appHeight)
appHeight();
console.log('wwe');

/*
================================================================
COOKIES
================================================================
*/

window.cookieconsent.initialise({
    "revokable": false,
    "palette": {
    //   "popup": {
    //     "background": "#000"
    //   },
      "button": {
        "background": "#f1d600"
      }
    },
    "theme": "classic",
    // "type": "opt-out"
    "content": {
        "dismiss": "Okay"
    }
  });


/*
================================================================
BUBBLING
================================================================
*/

// Listen to all clicks on the document
document.addEventListener('click', function (event) {
    console.log(event.target.classList);
	// PROJECT TEXT TOGGLE BUTTONS
	if (event.target.classList.contains('text-toggle-button')){
        toggleText();
    }
    if (event.target.classList.contains('close-button')){
        window.history.back();
        return false;
    }

}, false);


/*
================================================================
PROJECTS PAGE
================================================================
*/


function toggleText(){
    const images = document.getElementById('project-images');
    const text = document.getElementById('project-text');
    const toggleButton = document.querySelector('.text-toggle-button');
    if(images.classList.contains('active')){
        images.classList.remove('active');
        text.classList.add('active');
        toggleButton.innerHTML = "Images";
    }else{
        images.classList.add('active');
        text.classList.remove('active');
        toggleButton.innerHTML = "Text";
    }
}


/*
================================================================
SLIDESHOW
================================================================
*/

import TMslideshow from './slideshow';

function slideshowsInit(){
    const slideshows = document.querySelectorAll(".slideshow");

    for (var i = 0; i < slideshows.length; i++) {
        const slideshow = new TMslideshow(slideshows[i]);
    }
    
}


/*
================================================================
BARBA
================================================================
*/

if (history.scrollRestoration) {
    history.scrollRestoration = 'manual';
  }

const outRight = "100%";
const outLeft = "-100%";

let scrollX = 0;
let scrollY = 0;

let backgroundColour = '#ffffff';



barba.hooks.beforeLeave((data) => {
    closeMenu();
    // siteTitle.style.backgroundColor = "transparent";
    // menu.style.backgroundColor = "transparent";
  });

barba.hooks.beforeEnter((data) => {
    // window.scrollTo(0, 0);
});

barba.hooks.afterEnter((data) => {
    // window.scrollTo(0, 0);
    backgroundColour = window.getComputedStyle(data.next.container, null).getPropertyValue("background-color");
    // siteTitle.style.backgroundColor = backgroundColour;
    menu.style.backgroundColor = backgroundColour;
});

barba.hooks.after(() => {
    ga('set', 'page', window.location.pathname);
    ga('send', 'pageview');
  });

barba.init({
    schema: {
        prefix: 'data-page',
        wrapper: 'wrapper'
    },
    views: [
    {
        namespace: 'projects',
        beforeLeave(){
            scrollY = window.scrollY;
            console.log(scrollY);
        },
        beforeEnter() {
            console.log(scrollY);
            window.scrollTo(0, scrollY);
            // window.scrollTo(0, 0);
        },
    },
    {
        namespace: 'studio',
        beforeEnter() {
             scrollY = 0;
             window.scrollTo(0, 0);
        },
    },
    {
        namespace: 'article',
        beforeEnter() {
             scrollY = 0;
             window.scrollTo(0, 0);
        },
    },
    {
        namespace: 'home',
        beforeEnter() {
             scrollY = 0;
             window.scrollTo(0, 0);
        },
    },
    {
        namespace: 'archive',
        beforeEnter() {
             scrollY = 0;
             window.scrollTo(0, 0);
        },
    },
    {
        namespace: 'project',
        beforeEnter() {
             slideshowsInit();
        },
    },
    ],
    transitions: [
    {
        name: 'self',
        sync: true,
        leave(data) {
            
            
        },
        enter(data) {

            
        }
    },
    {
        name: 'enter-project',
        to: {
            namespace: [
                'project'
            ]
        },
        leave(data) {
            tm_body.classList.add('project-view');
        },
        enter(data) {
            return gsap.fromTo(data.next.container, {
            top: "100vh"
            },{
            top: "0",
            duration: .5,
            ease: "power4.inOut"
            });
        }
    },
    {
        name: 'exit-project',
        sync: true,
        from: {
            namespace: [
                'project'
            ]
        },
        leave(data) {
            
            return gsap.fromTo(data.current.container, {
                top: "0"
            },{
            top: "100vh",
            duration: .5,
            ease: "power4.inOut"
            });
        },
        enter(data) {

            
        }
    },
    {
        name: 'left <- right',
        sync: true,
        from: {
            custom: (data) => {
                return data.current.container.dataset.order < data.next.container.dataset.order;
              }
        },
        leave(data) {
            return gsap.fromTo(data.current.container, {
            left: "0%"
            },{
            left: outLeft,
            duration: .5,
            ease: "power4.inOut"
            });
        },
        enter(data) {
            return gsap.fromTo(data.next.container, {
            left: outRight
            },{
            left: "0%",
            duration: .5,
            ease: "power4.inOut"
            });
        }
    },
    {
        name: 'left -> right',
        sync: true,
        from: {
            custom: (data) => {
                return data.current.container.dataset.order > data.next.container.dataset.order;
              }
        },
        leave(data) {
            return gsap.fromTo(data.current.container, {
            left: "0%"
            },{
            left: outRight,
            duration: .5,
            ease: "power4.inOut"
            });
        },
        enter(data) {
            return gsap.fromTo(data.next.container, {
            left: outLeft
            },{
            left: "0%",
            duration: .5,
            ease: "power4.inOut"
            });
        }
    },
    {
        name: 'fade',
        sync: true,
        from: {
            custom: (data) => {
                return data.current.container.dataset.order == data.next.container.dataset.order;
              }
        },
        leave(data) {
            return gsap.fromTo(data.current.container, {
            opacity: 1
            },{
            opacity: 1,
            duration: .5,
            ease: "power4.inOut"
            });
        },
        enter(data) {
            return gsap.fromTo(data.next.container, {
            opacity: 0
            },{
            opacity: 1,
            duration: .5,
            ease: "power4.inOut"
            });
        }
    },]
});